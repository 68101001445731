import http from '@http'
// 获取核算组数据
export function getEnablingList(data) {
  return http.post('/calculation/getGroupList', data)
}

// 核算明细
export function getOutputList(data) {
  return http.post('/calculation/detail/list', data)
}

// 核算明细导出
export function getOutputListExport(data) {
  return http.downloadPost('/calculation/detail/export', data)
}

// 同步数据
export function synchroData(data) {
  return http.post('/calculation/detail/calculate', data)
}

// 设置负责人
export function setInternalSettlement(data) {
  return http.post('/calculation/setGroupLeader', data)
}
