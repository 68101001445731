<script>
import AppView from '@/components/AppView.vue'
import SuperTable from '@/components/SuperTable.vue'
import { useRouter } from '@core/utils'
import { useMessage } from '@/hooks/useMessage'
import { ref, reactive } from '@vue/composition-api'
import empty from '@/components/image/empty.png'
import { getEnablingList, getOutputList, getOutputListExport, synchroData } from './service'

export default {
  name: 'Details',
  components: { SuperTable, AppView },
  setup() {
    const e1 = 4
    const { confirm, message } = useMessage()
    const tableColumns = [
      { props: 'type', type:'seq', label: '序号', width: 60},
      { props: 'employeeName', label: '姓名' },
      { props: 'deptOneName', label: '一级部门' },
      { props: 'deptTwoName', label: '二级部门' },
      { props: 'deptThreeName', label: '三级部门' },
      { props: 'joinTime', label: '入职时间' },
      { props: 'title', label: '职位' },
      { props: 'workPlace', label: '办公地点' },
      { props: 'employeeType', label: '员工类型' },
      { props: 'employeeRank', label: '层级' },
      { props: 'groupProperties', label: '组别性质' },
      { props: 'commission', label: '提成(￥)', align: 'right' },
      { props: 'income', label: '收益(￥)', align: 'right' },
    ]
    const typeMap = {
      YT_BD: 'YT事业部',
      FB_BD: 'FB事业部',
      SLICE_BD: '影视事业部',
      X_BD: 'X项目',
      ORIGINAL_BD: '原创事业部',
    }
    const tableDate = ref([])
    const { route, router } = useRouter()
    const { type, month, status } = route.value?.query
    route.value.meta.pageTitle = typeMap[type] + '月度提成测算'
    const config = reactive({
      commissionTotal: '-', // 应发提成
      incomeTotal: '-', // 收益总和
      personCount: '-', // 核算人数
    })
    const userData = JSON.parse(localStorage.getItem('userData'))

    function init() {
      getOutputList({ deptCode: type, yearAndMonth: month }).then(({ data }) => {
        if (data.status === 200) {
          tableDate.value = data.data.list
          config.personCount = data.data.personCount || 0
          config.incomeTotal = data.data.incomeTotal || 0
          config.commissionTotal = data.data.commissionTotal || 0
        }
      })
    }

    function synchronousData() {
      const imp = () => {
        const data = {
          deptCode: type,
          type: 1,
          yearAndMonth: month
        }
        synchroData(data).then(({ data }) => {
          if (data.status === 200) {
            message.success('同步成功！')
            init()
          }
        }).catch(error => {
          message.error(error)
        })
      }
      if (status === '1') {
        confirm('当前核算组已核算，重新同步人员信息将重置核算结果，是否确认同步？', { type: 'warning' }).then(() => {
          imp()
        })
      } else {
        imp()
      }
    }

    function importData() {
      getOutputListExport({ deptCode: type, yearAndMonth: month }).then(response => {
        if (response.status !== 200) return message.error('导出失败！')
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
        const url = window.URL.createObjectURL(blob) // 创建一个临时的url指向blob对象
        const a = document.createElement('a')
        a.href = url
        a.download = `在 ${new Date().toLocaleString().replaceAll('/', '-')}导出的${typeMap[type]}月度提成测算报表`
        a.click()
        window.URL.revokeObjectURL(url)
      })
    }


    function countData() {
      const data = {
        deptCode: type,
        type: 2,
        yearAndMonth: month
      }
      synchroData(data).then(({ data }) => {
        if (data.status === 200) {
          message.success('计算成功！')
          if (status === '0') {
            router.replace({
              path: '/calculate/Details',
              query: {
                type: type,
                month: month,
                status: "1"
              }
            })
          } else init()
        }
      }).catch(error => {
        message.error(error)
      })
    }

    function getAuthList() {
      getEnablingList({ yearAndMonth: month }).then(({ data }) => {
        if (data.status === 200) {
          const list = data.data || []
          console.log(userData)
          const userId = userData.user.userId
          const findData = list.find(item => item.deptCode === type)
          if (!findData || !findData.chargePersonId?.split(',').some(item => item === userId)) {
            router.replace('/calculate/List')
            message.error('暂无权限！')

            return false
          }

          init()
        }
      })
    }

    getAuthList()

    return {
      e1,
      status,
      tableColumns,
      tableDate,
      importData,
      synchronousData,
      config,
      countData,
      empty
    }
  }
}
</script>

<template>
  <div class='details'>
    <div class='details-header flex-center'>
      <div class='details-header-content'>
        <v-stepper v-model="e1" elevation='0'>
          <v-stepper-header>
            <v-stepper-step
              :complete="e1 > 1"
              complete-icon='mdi-numeric-1'
              step="1"
            >
              <span class='fs16'>同步人员和收益信息</span>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="e1 > 2"
              complete-icon='mdi-numeric-2'
              step="2"
            >
              <span class='fs16'>检查信息是否正确</span>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="e1 > 3"
              complete-icon='mdi-numeric-3'
              step="3"
            >
              <span class='fs16'>发起计算</span>
            </v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </div>
    </div>
    <div class='details-body'>
      <app-view>
        <template #main-header>
          <div class='flex-left gap12 mt4 mb12'>
            <v-btn v-if='tableDate.length > 0' color='primary' @click='synchronousData'>同步人员和收益信息</v-btn>
            <v-btn outlined class='' @click='importData'>导出</v-btn>
          </div>
        </template>
        <SuperTable :columns="tableColumns" :data="tableDate">
          <template #empty>
            <div>
              <img :src='empty' alt='empty' style='max-width: 125px;' />
              <div class='mb16 mt18'>暂无数据，请先同步人员和收益信息</div>
              <v-btn color='primary' @click='synchronousData'>同步数据</v-btn>
            </div>
          </template>
        </SuperTable>
      </app-view>
    </div>
    <div class='details-footer flex-between px20'>
      <div class='flex-right fs15 gap40' style='font-weight: 500'>
        <div class='flex-center'>
          核算人数：
          <span class='fs16'>{{ config.personCount }}</span>
        </div>
        <div class='flex-center'>
          收益总和(￥)：
          <span class='fs16' style='color:#FFA80F'>{{ config.incomeTotal }}</span>
        </div>
        <div class='flex-center'>
          应发提成(¥)：
          <span class='fs16' style='color:#FFA80F'>{{ config.commissionTotal }}</span>
        </div>
      </div>
      <div class='flex-center gap12'>
        <v-btn outlined @click='$router.back()'>返回</v-btn>
        <v-btn color='primary' :disabled='tableDate.length === 0' @click='countData'>
          {{ status === "0" ? '计算提成' : '重新计算' }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped lang='scss'>
.details {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-header {
    height: 40px;

    &-content {
      max-width: 75%;
      min-width: 75%;
      transform: translateY(-10%);

      .v-stepper {
        background-color: transparent !important;
      }
    }
  }

  &-body {
    flex: auto;
  }

  &-footer {
    margin-top: 12px;
    height: 60px;
    background-color: #ffffff;
  }
}
</style>
